var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isAuth)?_c('v-app-bar',{attrs:{"color":"primary","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = true}}}),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.titulo))]),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"light-blue"}},'v-btn',attrs,false),on),[_c('v-badge',{attrs:{"content":_vm.leidos,"value":_vm.leidos,"color":"pink","overlap":""}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-bell ")])],1)],1)]}}],null,false,107394285)},[_c('v-card',{staticClass:"mx-auto",attrs:{"min-width":"500","max-width":"600"}},[_c('v-card-text',{attrs:{"id":"card-msg"}},[_c('h4',[_vm._v("SIN LEER ("+_vm._s(_vm.leidos)+")")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","fab":"","elevation":"0"},on:{"click":_vm.checkall}},[_c('v-icon',{style:(_vm.leidos ? 'color: red' : 'color: green;'),attrs:{"center":""}},[_vm._v(" mdi-email-multiple ")])],1)],1),_c('v-divider'),_c('v-virtual-scroll',{attrs:{"items":_vm.notificacion,"item-height":_vm.itemheight,"height":"185"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:item.id,class:{ active: item.estado === true },attrs:{"two-line":"","link":""}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item-content',_vm._g({class:{ 'color-text': item.estado === true },on:{"click":function($event){return _vm.toggleClass()}}},on),[_c('v-list-item-title',{class:[_vm.isActive ? 'wrap-text' : '']},[_vm._v(" "+_vm._s(item.mensaje))]),(item.mensaje2)?_c('v-list-item-subtitle',{class:[_vm.isActive ? 'wrap-text' : '']},[_vm._v(" "+_vm._s(item.mensaje2)+" ")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.mensaje))])]),_c('v-list-item-action',{staticClass:"icon",attrs:{"id":"icon2"}},[_c('v-btn',{attrs:{"small":"","fab":"","elevation":"0","icon":""},on:{"click":function($event){return _vm.check(item.id)}}},[(item.estado)?_c('v-icon',{attrs:{"color":"green","center":""}},[_vm._v(" mdi-email-check-outline ")]):_c('v-icon',{attrs:{"color":"red","center":""}},[_vm._v(" mdi-email-remove-outline ")])],1)],1)],1)]}}],null,false,3804564152)})],1)],1),_c('v-menu',{attrs:{"left":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.user)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-account")])],1)]}}],null,false,204398822)},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.profile}},[_c('v-list-item-avatar',[_c('img',{attrs:{"src":require("../assets/avatar.png"),"alt":"avatar"}})]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user))])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.clave}},[_c('v-list-item-title',[_vm._v("Cambiar Clave")])],1),_c('v-list-item',{on:{"click":_vm.logout}},[_c('v-list-item-title',[_vm._v("Salir "),_c('v-icon',{staticStyle:{"margin-left":"88px"}},[_vm._v("mdi-exit-to-app")])],1)],1)],1)],1)],1):_vm._e(),_c('v-navigation-drawer',{attrs:{"absolute":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"primary--text text--accent-4"},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},_vm._l((_vm.menu),function(m){return _c('v-list-item',{key:m.icon,attrs:{"href":m.href}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(m.icon))])],1),_c('v-list-item-title',[_vm._v(_vm._s(m.nombre))])],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }