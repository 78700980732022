/* eslint-disable */
import * as dotenv from "dotenv";
export const API_URL = process.env.VUE_APP_API_URL;
export const APP_SIMULADOR_URL = process.env.VUE_APP_SIMULADOR_URL;
export const TIPO_FACTURA = [
  { value: 1, text: "Facturas A" },
  { value: 2, text: "Notas de Debito A" },
  { value: 3, text: "Notas de Credito A" },
  { value: 4, text: "Recibos A" },
  { value: 5, text: "Notas de Venta al contado A" },
  { value: 6, text: "Facturas B" },
  { value: 7, text: "Notas de Debito B" },
  { value: 8, text: "Notas de Credito B" },
  { value: 9, text: "Recibos B" },
  { value: 10, text: "Notas de Venta al contado B" },
  { value: 11, text: "FACTURAS C" },
  { value: 12, text: "NOTAS DE DEBITO C" },
  { value: 13, text: "NOTAS DE CREDITO C" },
  { value: 15, text: "RECIBOS C" },
  { value: 19, text: "Facturas de Exportacion" },
  { value: 20, text: "N. Deb. p/operac. con el exterior" },
  { value: 21, text: "N. Cre. p/operac. con el exterior" },
  { value: 22, text: "Fac. Perm. Export. Simp. - Dto.855/97" },
  { value: 30, text: "Cbtes. compra de bienes usados" },
  { value: 31, text: "Mandato/Consignación" },
  {
    value: 32,
    text: "COMPROBANTES DE COMPRA DE MATERIALES A RECICLAR PROVENIENTES"
  },
  { value: 34, text: "Cbtes. A del Anexo I, Apartado A,inc.f),R.G.Nro. 1415" },
  { value: 35, text: "Cbtes. B del Anexo I,Apartado A,inc. f),R.G. Nro. 1415" },
  { value: 37, text: "N. Deb/doc. equiv. que cumplan con R.G.Nro. 1415" },
  { value: 38, text: "N. Cred/doc. equiv. que cumplan con R.G.Nro. 1415" },
  { value: 39, text: "Otros comprobantes A que cumplan con R.G.Nro. 1415" },
  { value: 40, text: "Otros comprobantes B que cumplan con R.G.Nro. 1415" },
  { value: 41, text: "OTROS COMPROBANTES C QUE CUMPLAN CON LA R.G. N° 1415" },
  { value: 51, text: "Facturas M" },
  { value: 52, text: "Notas de Debito M" },
  { value: 53, text: "Notas de credito M" },
  { value: 54, text: "Recibo M" },
  { value: 55, text: "Notas de Venta al contado M" },
  { value: 56, text: "Comprobantes M del anexo I, Apartado A,inc. f)R.G.Nro.1415" },
  { value: 57, text: "Otros Comprobantes M que cumplan con la R.G. Nro. 1415" },
  { value: 58, text: "Cuenta de Venta y Liquido producto M" },
  { value: 59, text: "Liquidacion M" },
  { value: 60, text: "Cta de Vta y Liquido prod. A" },
  { value: 61, text: "Cta de Vta y Liquido prod. B" },
  { value: 63, text: "Liquidacion A" },
  { value: 64, text: "Liquidacion B" },
  { value: 70, text: "Recibo de Factura de Credito R" },
  { value: 91, text: "Remito R" },
  { value: 49, text: "Comprobante de Compra de Bienes Usados" },
  { value: 81, text: "Tique Factura A" },
  { value: 82, text: "Tique Factura B" },
  { value: 83, text: "Tique" },
  { value: 111, text: "Tique Factura C" }
];
