import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';
import axios from 'axios';
import VueAxios from 'vue-axios';
import './plugins/vuetify-mask';
import EventBus from './plugins/EventBus';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false;
Vue.prototype.$bus = EventBus;
Vue.use(VueSweetalert2);

new Vue({
  router,
  vuetify,
  axios,
  VueAxios,
  render: (h) => h(App),
}).$mount('#app');
