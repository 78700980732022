import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
  },
  {
    path: '/catalogo',
    name: 'Catalogo',
    component: () => import('../views/Catalogo.vue'),
  },
  {
    path: '/solicitud/:id/:mostrarWizard/:tipoCredito/:operatoriaId',
    name: 'Fideicomiso',
    component: () => import('../views/Fideicomiso.vue'),
  },
  {
    path: '/lineas/:id/:mostrarWizard/:tipoCredito/:servicioid',
    name: 'LineaCatalogo',
    component: () => import('../views/Fideicomiso.vue'),
  },
  {
    path: '/creditoView/:solicitud_id',
    name: 'Credito',
    component: () => import('../views/creditoView.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
  },
  {
    path: '/afterVerifyError',
    name: 'afterVerifyError',
    component: () => import('../views/afterVerifyError.vue'),
  },
  {
    path: '/afterVerifyOkError',
    name: 'afterVerifyOkError',
    component: () => import('../views/afterVerifyOkError.vue'),
  },
  {
    path: '/afterVerifyOk',
    name: 'afterVerifyOk',
    component: () => import('../views/afterVerifyOk.vue'),
  },
  {
    path: '*',
    component: () => import('../views/404.vue'),
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('../views/Profile.vue'),
  },
  {
    path: '/pass',
    name: 'Pass',
    component: () => import('../views/Pass.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && to.name !== 'Register' && to.name !== 'Catalogo' && to.name !== 'afterVerifyError' && to.name !== 'afterVerifyOk' && !localStorage.getItem('token')) next({ name: 'Login' });
  else next();
});
export default router;
